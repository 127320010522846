import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FrontPage from '../../pages/front-page/FrontPage';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<FrontPage />} />
    </Routes>
  );
};

export default AppRoutes;
