import axios from 'axios';
import { NewsletterData } from '../models/NewsletterData';

export const addToNewsletter = async (data: NewsletterData): Promise<any[]> => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const baseURL = process.env.REACT_APP_BACKEND_URL;

  return axios
    .create({ baseURL, headers })
    .put<any[]>('/early-access/add', data)
    .then((response: any) => response?.data);
};
