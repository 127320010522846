import React from 'react';
import './App.scss';
import AppRoutes from './shared/routes/AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

const App = () => {
  return (
    <div className="page">
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
};

export default App;
