import React from 'react';
import './Hamburger.scss';
import { HamburgerProps } from './models/HamburgerProps';

const Hamburger: React.FC<HamburgerProps> = ({ onClick, isActive }) => {
  return (
    <div className="hamburger">
      <div className="logo-box">
        <div className="logo-box__logo" />
      </div>
      <div
        className={isActive ? `hamburger__menu hamburger__menu--active` : 'hamburger__menu'}
        onClick={onClick}
      >
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

export default Hamburger;
